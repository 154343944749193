import { get, post } from '../../../../api/index';

export const newpaperInfo = params => get(`/api/newpaper/v1/info`, params); // 考试要求
export const paperInfo = params => get(`/api/newtk/v1/paper/info`, params); // 查看试卷整体信息
export const answerSubmit = params => post(`/api/newtk/v1/answer/submit`, params); // 作答保存/交卷
export const answerReport = params => get(`/api/newtk/v1/answer/report`, params); // 试卷报告
export const escalation = params => get(`/api/newpaper/v1/escalation`, params); // 异常上报（初始化）
export const escalationLog = params => get(`/api/newpaper/v1/escalation/log`, params); // 异常日志上报
export const Heartbeat = params => get(`/api/newtk/v1/answer/heartbeat`, params); // 考试禁用多端的上报接口
export const check = params => get(`/api/newpaper/v1/check`, params); // 考试检测
export const autosubmit = params => post(`/api/newtk/v1/answer/save`, params); // 自动保存
