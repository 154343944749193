<template>
  <div>
    <div class="detailThought">
      <div class="detailThoughtdiv1" v-if="thought_dial.retype == 4">
        <h6>你的答案</h6>
        <div
          v-if="thought_dial.user_answer == ''"
          style="color: #888"
          class="detailThoughtdiv1answer"
        >
          未作答
        </div>
        <template v-else>
          <div
            class="detailThoughtdiv1answer"
            v-html="thought_dial.user_answer"
          ></div>
          <ul
            class="detailThoughtdiv1oul clearfix"
            v-if="thought_dial.user_pic.length > 0"
          >
            <li v-for="(item, index) in thought_dial.user_pic" :key="index">
              <img
                :src="item.url"
                alt=""
                v-if="item.url"
                @click="checkImg(item.url)"
              />
            </li>
          </ul>
          <el-dialog :visible.sync="dialogVisible">
            <img width="100%" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </template>
        <div
          class="teacherdp"
          v-if="thought_dial.retype == 4 && thought_dial.assess != ''"
        >
          <i class="teacherdpi"
            >老师批阅<em>{{
              thought_dial.last_time | formatimes("yyyy-MM-dd hh:mm")
            }}</em></i
          >
          <div v-html="thought_dial.assess" class="teacherdpcon"></div>
        </div>
      </div>
      <div class="detailThoughtdiv" v-else-if="thought_dial.retype == 5">
        <img
          src="../../assets/images/right.png"
          alt=""
          v-if="thought_dial.result.toString() == thought_dial.radio.toString()"
        />
        <img src="../../assets/images/error.png" alt="" v-else />
        <span
          :class="
            thought_dial.result.toString() == thought_dial.radio.toString()
              ? 'zq'
              : 'err'
          "
        >
          回答{{
            thought_dial.result.toString() == thought_dial.radio.toString()
              ? "正确"
              : "错误"
          }}
        </span>
      </div>
      <div class="detailThoughtdiv" v-else>
        <img
          src="../../assets/images/right.png"
          alt=""
          v-if="thought_dial.result.toString() == thought_dial.radio.toString()"
        />
        <img src="../../assets/images/error.png" alt="" v-else />
        <span
          :class="
            thought_dial.result.toString() == thought_dial.radio.toString()
              ? 'zq'
              : 'err'
          "
          style="margin-right: 15px"
        >
          回答{{
            thought_dial.result.toString() == thought_dial.radio.toString()
              ? "正确"
              : "错误"
          }}
        </span>
        正确答案是
        <span class="zq">{{ thought_dial.result.toString() }}</span>
        ，你的答案是
        <span
          :class="
            thought_dial.result.toString() == thought_dial.radio.toString()
              ? 'zq'
              : 'err'
          "
          >{{
            thought_dial.user_answer != "" ||
            thought_dial.user_answer.length != 0
              ? thought_dial.radio.toString()
              : "未作答"
          }}</span
        >
        。
      </div>
      <template v-if="thought_dial.reacType">
        <div class="jiexi">答案解析</div>
        <div class="thoughtDetail" v-html="thought_dial.analysis"></div>
      </template>
    </div>
  </div>
</template>

<script>
// import imgDialog from "@p/user/components/com/imgDialog.vue";
export default {
  props: {
    thought_dial: Object,
  },
  // components: { imgDialog },
  data() {
    return {
      reacType: false,
      dialogImageUrl: "",
      dialogVisible: false,
    };
  },
  mounted() {},
  methods: {
    checkImg(data) {
      // this.$refs.ImgDialog.openInit(data);
      this.dialogImageUrl = data;
      this.dialogVisible = true;
    },
  },
};
</script>

<style scoped lang=""></style>
