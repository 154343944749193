var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"answerWrap"},[_c('div',{staticClass:"answerBtn",on:{"click":_vm.upOrDown}},[_vm._m(0),_vm._v(" 答题卡 "),(!_vm.answerShow)?_c('i',{staticClass:"el-icon-arrow-up rightIcon"}):_c('i',{staticClass:"el-icon-arrow-down rightIcon"})]),(_vm.answerShow)?_c('div',{staticClass:"answerList"},[(_vm.reacType)?_c('ul',{staticClass:"answerListoul"},[_c('li',[_vm._v("正确")]),_c('li',[_vm._v("错误")]),_c('li',[_vm._v("论述题")]),_c('li',[_vm._v("未答")])]):_c('ul',{staticClass:"answerListoul"},[_c('li',{staticStyle:{"display":"none"}}),_c('li',{staticStyle:{"display":"none"}}),_c('li',[_vm._v("已答")]),_c('li',[_vm._v("未答")])]),(_vm.reacType)?[(_vm.type)?_c('div',{staticClass:"ansQue"},_vm._l((_vm.answerTotal),function(ite,inde){return _c('div',{key:inde},[(ite.children.length > 0)?_c('h2',{staticClass:"fontSize16"},[_vm._v(" "+_vm._s(ite.part_title)+" ")]):_vm._e(),_vm._l((ite.children),function(item,index){return _c('div',{key:index,staticStyle:{"display":"inline"}},[(!item.children)?[_c('em',{on:{"click":function($event){return _vm.jump(item.seq)}}},[_vm._v(" "+_vm._s(item.question_seq)+" "),_c('span',{staticClass:"iconfont",class:{
                    'icon-right right': item.right == 0,
                    'icon-wrong wrong': item.right == 1,
                    'icon-lunshu lunshu': item.right == 3,
                  }})]),(item.paixu % 5 == 0 && item.paixu % 15 != 0)?_c('i'):_vm._e()]:_vm._e(),(item.children)?_vm._l((item.children),function(item1,index1){return _c('strong',{key:index1,staticStyle:{"font-weight":"normal"}},[_c('em',{on:{"click":function($event){return _vm.jump(item1.seq)}}},[_vm._v(" "+_vm._s(item1.question_seq)+" "),_c('span',{staticClass:"iconfont",class:{
                      'icon-right right': item1.right == 0,
                      'icon-wrong wrong': item1.right == 1,
                      'icon-lunshu lunshu': item1.right == 3,
                    }})]),(item1.paixu % 5 == 0 && item1.paixu % 15 != 0)?_c('i'):_vm._e()])}):_vm._e()],2)})],2)}),0):_c('div',{staticClass:"ansQue"},_vm._l((_vm.answerTotal),function(item,index){return _c('span',{key:index},[(!item.children)?[_c('em',{on:{"click":function($event){return _vm.jump(item.seq)}}},[_vm._v(" "+_vm._s(item.question_seq)+" "),_c('span',{staticClass:"iconfont",class:{
                  'icon-right right': item.right == 0,
                  'icon-wrong wrong': item.right == 1,
                  'icon-lunshu lunshu': item.right == 3,
                }})]),(item.paixu % 5 == 0 && item.paixu % 15 != 0)?_c('i'):_vm._e()]:_vm._e(),(item.children)?_vm._l((item.children),function(item1,index1){return _c('strong',{key:index1,staticStyle:{"font-weight":"normal"}},[_c('em',{on:{"click":function($event){return _vm.jump(item1.seq)}}},[_vm._v(" "+_vm._s(item1.question_seq)+" "),_c('span',{staticClass:"iconfont",class:{
                    'icon-right right': item1.right == 0,
                    'icon-wrong wrong': item1.right == 1,
                    'icon-lunshu lunshu': item1.right == 3,
                  }})]),(item1.paixu % 5 == 0 && item1.paixu % 15 != 0)?_c('i'):_vm._e()])}):_vm._e()],2)}),0)]:[(_vm.type)?_c('div',{staticClass:"ansQue"},_vm._l((_vm.answerTotal),function(ite,inde){return _c('div',{key:inde},[(ite.children.length > 0)?_c('h2',{staticClass:"fontSize16"},[_vm._v(" "+_vm._s(ite.part_title)+" ")]):_vm._e(),_vm._l((ite.children),function(item,index){return _c('div',{key:index,staticStyle:{"display":"inline"}},[(!item.children)?[_c('em',{on:{"click":function($event){return _vm.jump(item.seq)}}},[_vm._v(" "+_vm._s(item.question_seq)+" "),_c('span',{staticClass:"iconfont",class:{
                    'icon-right checkColor':
                      item.radio != '' || item.radio.length > 0|| item.user_pic.length>0,
                  }})]),(item.paixu % 5 == 0 && item.paixu % 15 != 0)?_c('i'):_vm._e()]:_vm._e(),(item.children)?_vm._l((item.children),function(item1,index1){return _c('strong',{key:index1,staticStyle:{"font-weight":"normal"}},[_c('em',{on:{"click":function($event){return _vm.jump(item1.seq)}}},[_vm._v(" "+_vm._s(item1.question_seq)+" "),_c('span',{staticClass:"iconfont",class:{
                      'icon-right checkColor':
                        item1.radio != '' || item1.radio.length > 0|| item1.user_pic.length>0,
                    }})]),(item1.paixu % 5 == 0 && item1.paixu % 15 != 0)?_c('i'):_vm._e()])}):_vm._e()],2)})],2)}),0):_c('div',{staticClass:"ansQue"},_vm._l((_vm.answerTotal),function(item,index){return _c('span',{key:index},[(!item.children)?[_c('em',{on:{"click":function($event){return _vm.jump(item.seq)}}},[_vm._v(" "+_vm._s(item.question_seq)+" "),_c('span',{staticClass:"iconfont",class:{
                  'icon-right checkColor':
                    item.radio != '' || item.radio.length > 0|| item.user_pic.length>0,
                }})]),(item.paixu % 5 == 0 && item.paixu % 15 != 0)?_c('i'):_vm._e()]:_vm._e(),(item.children)?_vm._l((item.children),function(item1,index1){return _c('strong',{key:index1,staticStyle:{"font-weight":"normal"}},[_c('em',{on:{"click":function($event){return _vm.jump(item1.seq)}}},[_vm._v(" "+_vm._s(item1.question_seq)+" "),_c('span',{staticClass:"iconfont",class:{
                    'icon-right checkColor':
                      item1.radio != '' || item1.radio.length > 0|| item1.user_pic.length>0,
                  }})]),(item1.paixu % 5 == 0 && item1.paixu % 15 != 0)?_c('i'):_vm._e()])}):_vm._e()],2)}),0)]],2):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('i',{staticClass:"leftIcon"},[_c('img',{attrs:{"src":require("../../assets/images/icon_dp.png"),"alt":""}})])}]

export { render, staticRenderFns }