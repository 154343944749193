<template>
  <div class="answerWrap">
    <div class="answerBtn" @click="upOrDown">
      <i class="leftIcon"
        ><img src="../../assets/images/icon_dp.png" alt=""
      /></i>
      答题卡
      <i class="el-icon-arrow-up rightIcon" v-if="!answerShow"></i>
      <i class="el-icon-arrow-down rightIcon" v-else></i>
    </div>
    <div class="answerList" v-if="answerShow">
      <ul class="answerListoul" v-if="reacType">
        <li>正确</li>
        <li>错误</li>
        <li>论述题</li>
        <li>未答</li>
      </ul>
      <ul class="answerListoul" v-else>
        <li style="display: none"></li>
        <li style="display: none"></li>
        <li>已答</li>
        <li>未答</li>
      </ul>
      <template v-if="reacType">
        <div class="ansQue" v-if="type">
          <div v-for="(ite, inde) in answerTotal" :key="inde">
            <h2 class="fontSize16" v-if="ite.children.length > 0">
              {{ ite.part_title }}
            </h2>
            <div
              style="display: inline"
              v-for="(item, index) in ite.children"
              :key="index"
            >
              <template v-if="!item.children">
                <em @click="jump(item.seq)">
                  {{ item.question_seq }}
                  <span
                    class="iconfont"
                    :class="{
                      'icon-right right': item.right == 0,
                      'icon-wrong wrong': item.right == 1,
                      'icon-lunshu lunshu': item.right == 3,
                    }"
                  ></span>
                </em>
                <i v-if="item.paixu % 5 == 0 && item.paixu % 15 != 0"></i>
              </template>
              <template v-if="item.children">
                <strong
                  v-for="(item1, index1) in item.children"
                  :key="index1"
                  style="font-weight: normal"
                >
                  <em @click="jump(item1.seq)">
                    {{ item1.question_seq }}
                    <span
                      class="iconfont"
                      :class="{
                        'icon-right right': item1.right == 0,
                        'icon-wrong wrong': item1.right == 1,
                        'icon-lunshu lunshu': item1.right == 3,
                      }"
                    ></span>
                  </em>
                  <i v-if="item1.paixu % 5 == 0 && item1.paixu % 15 != 0"></i>
                </strong>
              </template>
            </div>
          </div>
        </div>
        <div class="ansQue" v-else>
          <span v-for="(item, index) in answerTotal" :key="index">
            <template v-if="!item.children">
              <em @click="jump(item.seq)">
                {{ item.question_seq }}
                <span
                  class="iconfont"
                  :class="{
                    'icon-right right': item.right == 0,
                    'icon-wrong wrong': item.right == 1,
                    'icon-lunshu lunshu': item.right == 3,
                  }"
                ></span>
              </em>
               <i v-if="item.paixu % 5 == 0 && item.paixu % 15 != 0"></i>
            </template>
            <template v-if="item.children">
              <strong
                v-for="(item1, index1) in item.children"
                :key="index1"
                style="font-weight: normal"
              >
                <em @click="jump(item1.seq)">
                  {{ item1.question_seq }}
                  <span
                    class="iconfont"
                    :class="{
                      'icon-right right': item1.right == 0,
                      'icon-wrong wrong': item1.right == 1,
                      'icon-lunshu lunshu': item1.right == 3,
                    }"
                  ></span>
                </em>
                 <i v-if="item1.paixu % 5 == 0 && item1.paixu % 15 != 0"></i>
              </strong>
            </template>
          </span>
        </div>
      </template>
      <template v-else>
        <div class="ansQue" v-if="type">
          <div v-for="(ite, inde) in answerTotal" :key="inde">
            <h2 class="fontSize16" v-if="ite.children.length > 0">
              {{ ite.part_title }}
            </h2>
            <div
              style="display: inline"
              v-for="(item, index) in ite.children"
              :key="index"
            >
              <template v-if="!item.children">
                <em @click="jump(item.seq)">
                  {{ item.question_seq }}
                  <span
                    class="iconfont"
                    :class="{
                      'icon-right checkColor':
                        item.radio != '' || item.radio.length > 0|| item.user_pic.length>0,
                    }"
                  ></span>
                </em>
                <i v-if="item.paixu % 5 == 0 && item.paixu % 15 != 0"></i>
              </template>
              <template v-if="item.children">
                <strong
                  v-for="(item1, index1) in item.children"
                  :key="index1"
                  style="font-weight: normal"
                >
                  <em @click="jump(item1.seq)">
                    {{ item1.question_seq }}
                    <span
                      class="iconfont"
                      :class="{
                        'icon-right checkColor':
                          item1.radio != '' || item1.radio.length > 0|| item1.user_pic.length>0,
                      }"
                    ></span>
                  </em>
                  <i v-if="item1.paixu % 5 == 0 && item1.paixu % 15 != 0"></i>
                </strong>
              </template>
            </div>
          </div>
        </div>
        <div class="ansQue" v-else>
          <span v-for="(item, index) in answerTotal" :key="index">
            <template v-if="!item.children">
              <em @click="jump(item.seq)">
                {{ item.question_seq }}
                <span
                  class="iconfont"
                  :class="{
                    'icon-right checkColor':
                      item.radio != '' || item.radio.length > 0|| item.user_pic.length>0,
                  }"
                ></span>
              </em>
              <i v-if="item.paixu % 5 == 0 && item.paixu % 15 != 0"></i>
            </template>
            <template v-if="item.children">
              <strong
                v-for="(item1, index1) in item.children"
                :key="index1"
                style="font-weight: normal"
              >
                <em @click="jump(item1.seq)">
                  {{ item1.question_seq }}
                  <span
                    class="iconfont"
                    :class="{
                      'icon-right checkColor':
                        item1.radio != '' || item1.radio.length > 0|| item1.user_pic.length>0,
                    }"
                  ></span>
                </em>
                <i v-if="item1.paixu % 5 == 0 && item1.paixu % 15 != 0"></i>
              </strong>
            </template>
          </span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    answerTotal: Array,
    type: Boolean,
    reacType: Boolean,
  },
  data() {
    return {
      answerShow: false,
    };
  },
  mounted() {},
  methods: {
    upOrDown() {
      this.answerShow = !this.answerShow;
    },
    jump(data) {
      let t = document.querySelector("#question" + data).offsetTop;
      window.scrollTo(0, t - 88);
    },
  },
};
</script>

<style scoped lang=""></style>
