<template>
  <!-- 论述 -->
  <div class="discussWrap" :id="'question' + list.seq">
    <b class="itemNum">{{ list.label }}<span class="detailScore">（{{list.question_score}}分）</span></b>
    <div class="itemDetail">
      <div class="detailTitle">
        <div class="mb10">
          <span class="detailMate" v-if="is_stuff == 1">资料</span>
          <span class="detailCate">论述题</span>
          <div class="detailContent" v-html="list.title"></div>
        </div>
      </div>
<!--      <div class="detailOption">-->
<!--        <div class="optionWrap" v-if="!list.subFlag">-->
<!--          <el-input-->
<!--            type="textarea"-->
<!--            :rows="8"-->
<!--            placeholder="请输入内容"-->
<!--            v-model="list.radio"-->
<!--            class="mb20"-->
<!--          ></el-input>-->
<!--          <el-upload-->
<!--            :data="upld"-->
<!--            action="https://jsonplaceholder.typicode.com/posts/"-->
<!--            list-type="picture-card"-->
<!--            :limit="9"-->
<!--            :file-list="list.stu_img"-->
<!--            :on-preview="handlePictureCardPreview"-->
<!--            :on-remove="handleRemove"-->
<!--            :on-success="sucesspic"-->
<!--          >-->
<!--            <i class="el-icon-plus"></i>-->
<!--            <br />-->
<!--            <span>添加图片</span>-->
<!--          </el-upload>-->
<!--          <el-dialog :visible.sync="dialogVisible">-->
<!--            <img width="100%" :src="dialogImageUrl" alt="" />-->
<!--          </el-dialog>-->
<!--        </div>-->
<!--        <span class="queding" @click="sub()" v-if="!list.subFlag">确定</span>-->
<!--      </div>-->
      <Point :thought_dial="list" :reacType="list.reacType"></Point>
    </div>
    <i></i>
  </div>
</template>

<script>
import Point from "./com/Point.vue";
export default {
  props: {
    list: Object,
    is_stuff: Number,
  },
  components: {
    Point,
  },
  data() {
    return {

    };
  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style scoped lang=""></style>
