<template>
  <div>
    <div class="topFix">
      <el-button type="info" class="back" @click="returnBack()"
        ><i class="el-icon-arrow-left"></i>返回</el-button
      >
      <h2 class="lineH38">{{ new_shell_answer.source_name }}</h2>
      <el-button
        type="primary"
        @click="tozd()"
        v-if="
          ((new_shell_answer.reply_num == 0 ||
            new_shell_answer.reply_num - new_shell_answer.repeat_num > 0) &&
          new_shell_answer.is_end == 0)||(new_shell_answer.allow_reply!=0&&new_shell_answer.is_end == 0)
        "
        >重新作答</el-button
      >
      <p v-else style="width: 116px; height: 38px"></p>
    </div>
    <div v-if="$route.query.allow_students_answer==0" class="bgfffs">
      <img src="./assets/images/img.png" alt="">
    </div>
    <div v-else class="contMain">
      <div class="bgFFF pt30 pb40 pl30 pr30 mb20 borR8">
        <h3 class="types"><b>我的成绩</b></h3>
        <div class="gradeTotal">
          <dl v-if="(new_shell_answer.is_end == 0&&new_shell_answer.allow_students_answer == 1&&new_shell_answer.look_score == 1)||new_shell_answer.is_end == 1">
            <dt><img src="./assets/images/xs_icon01.png" alt="" /></dt>
            <dd>
              <b>{{ report.total_score_string }}</b
              >/{{ info1.total_score }}分
              <p>我的</p>
            </dd>
          </dl>
          <dl>
            <dt><img src="./assets/images/xs_icon02.png" alt="" /></dt>
            <dd>
              <template v-if="hour!=0">
                <b>{{ hour }}</b
              >小时
              </template>
              <template v-if="middle!=0">
                <b>{{ middle }}</b
              >分
              </template>
              <template v-if="theTime!=0">
                <b>{{ theTime }}</b
              >秒
              </template>
              <p>用时</p>
            </dd>
          </dl>
          <dl v-if="(new_shell_answer.is_end == 0&&new_shell_answer.allow_students_answer == 1&&new_shell_answer.look_score == 1)||new_shell_answer.is_end == 1">
            <dt><img src="./assets/images/xs_icon03.png" alt="" /></dt>
            <dd>
              <b>{{ report.average_score }}</b
              >分
              <p>平均</p>
            </dd>
          </dl>
          <dl v-if="(new_shell_answer.is_end == 0&&new_shell_answer.allow_students_answer == 1&&new_shell_answer.look_score == 1)||new_shell_answer.is_end == 1">
            <dt><img src="./assets/images/xs_icon04.png" alt="" /></dt>
            <dd>
              <b>{{ report.max_score }}</b
              >分
              <p>最高</p>
            </dd>
          </dl>
          <dl v-if="(new_shell_answer.is_end == 0&&new_shell_answer.allow_students_answer == 1&&new_shell_answer.look_score == 1)||new_shell_answer.is_end == 1">
            <dt><img src="./assets/images/xs_icon05.png" alt="" /></dt>
            <dd>
              <b>{{ report.beat_percent }}</b
              >
              <p>击败考生</p>
            </dd>
          </dl>
        </div>
      </div>
      <div class="summary" v-if="(new_shell_answer.is_end == 0&&new_shell_answer.allow_students_answer == 1&&new_shell_answer.react_type == 1)||new_shell_answer.is_end == 1">
        <span class="right">正确</span>
        <span class="error">错误</span>
        <span class="discuss">论述题</span>
        <span class="not">未答</span>
        <em>共计：{{ info1.question_num }}道题</em>
        <em v-if="new_shell_answer.time_limit > 0"
          >限时：{{ new_shell_answer.time_limit }}分钟</em
        >
        <em v-else>限时：不限时</em>
        <el-checkbox v-model="errors" class="ml20" @change="lookshiti()"
          >只看错题</el-checkbox
        >
      </div>
      <main>
        <div class="mainWrap">
          <template v-if="isBftrue">
            <div v-for="(item1, index1) in data1" :key="index1">
              <!-- 部分 -->
              <h3 class="types">
                <b>{{ item1.part_title }}</b>
              </h3>
              <p class="intr">
                {{ item1.description }}
              </p>
              <div class="testQuestions">
                <div v-for="(item, index) in item1.children" :key="index">
                  <!-- 单选 -->
                  <Radio
                    class="itemWrap"
                    v-if="item.is_stuff == 0 && item.retype == 1"
                    :list="item"
                    :is_stuff="item.is_stuff"
                  ></Radio>
                  <!-- 多选 -->
                  <Checkbox
                    class="itemWrap"
                    v-if="
                      (item.is_stuff == 0 && item.retype == 2) ||
                      (item.is_stuff == 0 && item.retype == 3)
                    "
                    :list="item"
                    :is_stuff="item.is_stuff"
                  ></Checkbox>
                  <!-- 判断 -->
                  <Estimate
                    class="itemWrap"
                    v-if="item.is_stuff == 0 && item.retype == 5"
                    :list="item"
                    :is_stuff="item.is_stuff"
                  ></Estimate>
                  <!-- 论述 -->
                  <Discuss
                    class="itemWrap"
                    v-if="item.is_stuff == 0 && item.retype == 4"
                    :list="item"
                    :is_stuff="item.is_stuff"
                  ></Discuss>
                  <!-- 资料 -->
                  <Materials
                    class="itemWrap"
                    v-if="item.is_stuff == undefined"
                    :list="item"
                  ></Materials>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="testQuestions">
              <div v-for="(item, index) in data1" :key="index">
                <!-- 单选 -->
                <Radio
                  class="itemWrap"
                  v-if="item.is_stuff == 0 && item.retype == 1"
                  :list="item"
                  :is_stuff="item.is_stuff"
                ></Radio>
                <!-- 多选 -->
                <Checkbox
                  class="itemWrap"
                  v-if="
                    (item.is_stuff == 0 && item.retype == 2) ||
                    (item.is_stuff == 0 && item.retype == 3)
                  "
                  :list="item"
                  :is_stuff="item.is_stuff"
                ></Checkbox>
                <!-- 判断 -->
                <Estimate
                  class="itemWrap"
                  v-if="item.is_stuff == 0 && item.retype == 5"
                  :list="item"
                  :is_stuff="item.is_stuff"
                ></Estimate>
                <!-- 论述 -->
                <Discuss
                  class="itemWrap"
                  v-if="item.is_stuff == 0 && item.retype == 4"
                  :list="item"
                  :is_stuff="item.is_stuff"
                ></Discuss>
                <!-- 资料 -->
                <Materials
                  class="itemWrap"
                  v-if="item.is_stuff == undefined"
                  :list="item"
                ></Materials>
              </div>
            </div>
          </template>
        </div>
      </main>
      <AnswerSheet
        :answerTotal="this.data1"
        :type="isBftrue"
        :reacType="reacType"
      ></AnswerSheet>
    </div>
  </div>
</template>

<script>
import { answerReport } from "./assets/api";
import Radio from "./componentsRecite/Radio"; // 单选
import Checkbox from "./componentsRecite/Checkbox"; // 多选
import Estimate from "./componentsRecite/Estimate"; // 判断
import Discuss from "./componentsRecite/Discuss"; // 论述
import Materials from "./componentsRecite/Materials"; // 材料
import AnswerSheet from "./componentsRecite/com/AnswerSheet"; //答题卡
export default {
  name: "examReport",
  components: {
    Radio,
    Checkbox,
    Estimate,
    Discuss,
    Materials,
    AnswerSheet,
  },
  data() {
    return {
      errors: false,
      new_shell_answer: {},
      info1: {},
      isBftrue: false,
      data1: [],
      report: {},
      reacType: false,// true为可查看答案 false为不允许看答案
      question_show_listerror: [], //错误试题
      isExam:2,  //是否是考试1 作业 2考试
      theTime:0,// 秒
      middle:0,// 分
      hour:0
    };
  },
  mounted() {
    if(this.$route.query.isExam!=undefined){
      this.isExam=this.$route.query.isExam
    }
    this.answerReport();
  },
  methods: {
    returnBack() {
      this.$router.go(-1);
    },
    tozd() {
      if(this.isExam==2){
        this.$router.push({
          path: "/examRequire",
          query: {
            course_id: this.$route.query.course_id,
            id: this.$route.query.id,
            answer_id: this.$route.query.answer_id,
            paper_id: this.$route.query.paper_id,
            cst_id: this.$route.query.cst_id,
          },
        });
      }else {
        this.$router.push({
          path: "/studentsExam",
          query: {
            course_id: this.$route.query.course_id,
            id: this.$route.query.id,
            answer_id: this.$route.query.answer_id,
            paper_id: this.$route.query.paper_id,
            cst_id: this.$route.query.cst_id,
            isExam:1
          },
        });
      }
    },
    // 获取作答报告
    answerReport() {
      const params = {
        answer_id: this.$route.query.answer_id,
      };
      answerReport(params).then((res) => {
        if (res.code == 0) {
          this.info1 = res.data.paper_show;
          this.report = res.data.report;
          this.second(this.report.used_time)
          this.new_shell_answer = res.data.new_shell_answer;
          //reacType  true为可查看答案 false为不允许看答案
          if(this.new_shell_answer.is_end == 1){
            this.reacType=true
          }else {
            this.reacType = this.new_shell_answer.allow_students_answer == 1&&this.new_shell_answer.react_type == 1 ? true : false;
          }
          this.numZc(
            this.info1.paper_part,
            this.info1.stuff_info,
            this.info1.question_show_list
          );
        }
      });
    },
    second(value) {
      this.theTime = parseInt(value);// 秒
      if(this.theTime >= 60) {
        this.middle= parseInt(this.theTime/60);
        this.theTime = parseInt(this.theTime%60);
          if(this.middle>= 60) {
            this.hour= parseInt(this.middle/60);
            this.middle= parseInt(this.middle%60);
          }
      }
    },
    lookshiti() {
      if (this.errors) {
        this.question_show_listerror = JSON.parse(
          JSON.stringify(
            this.info1.question_show_list.filter((item) => item.right == 1)
          )
        );
        this.numZc(
          this.info1.paper_part,
          this.info1.stuff_info,
          this.question_show_listerror
        );
      } else {
        this.numZc(
          this.info1.paper_part,
          this.info1.stuff_info,
          this.info1.question_show_list
        );
      }
    },
    // 判断是否有部分
    isHavaBf(data) {
      this.isBftrue = data.some((item) => item.type == 1);
    },
    //数据处理
    numZc(paper_part, stuff_info, question_show_list) {
      if (question_show_list.length > 0) {
        question_show_list.map((questionshowlistitem) => {
          this.$set(questionshowlistitem, "type", 3);
          this.$set(questionshowlistitem, "reacType", this.reacType);
          if (questionshowlistitem.user_pic == null) {
            this.$set(questionshowlistitem, "user_pic", []);
          }
          if (
            questionshowlistitem.retype == 2 ||
            questionshowlistitem.retype == 3
          ) {
            if (questionshowlistitem.user_answer != "") {
              let ss = questionshowlistitem.user_answer.split(",");
              this.$set(questionshowlistitem, "radio", ss);
            } else {
              this.$set(questionshowlistitem, "radio", []);
            }
            if (questionshowlistitem.user_answer != "") {
              this.sub(questionshowlistitem);
            }
          } else if (
            questionshowlistitem.retype == 1 ||
            questionshowlistitem.retype == 5
          ) {
            this.$set(
              questionshowlistitem,
              "radio",
              questionshowlistitem.user_answer
            );
            if (questionshowlistitem.user_answer != "") {
              this.radioOptions(questionshowlistitem);
            }
          } else {
            this.$set(
              questionshowlistitem,
              "radio",
              questionshowlistitem.user_answer
            );
            if (questionshowlistitem.user_answer != "") {
              this.$set(questionshowlistitem, "right", 3);
            }
          }
        });
      }
      if (stuff_info.length > 0) {
        stuff_info.map((stuffinfoitem) => {
          this.$set(stuffinfoitem, "type", 2);
          this.$set(stuffinfoitem, "children", []);
          if (question_show_list.length > 0) {
            question_show_list.map((questionshowlistitem) => {
              if (stuffinfoitem.id == questionshowlistitem.stuff_id) {
                this.$set(
                  stuffinfoitem,
                  "part_id",
                  questionshowlistitem.part_id
                );
              }
            });
          }
        });
      }
      if (paper_part != null && paper_part.length > 0) {
        paper_part.map((paperpartitem) => {
          this.$set(paperpartitem, "type", 1);
          this.$set(paperpartitem, "children", []);
          if (question_show_list.length > 0) {
            question_show_list.map((questionshowlistitem) => {
              if (questionshowlistitem.is_stuff == 1) {
                stuff_info.map((stuffinfoitem) => {
                  if (questionshowlistitem.stuff_id == stuffinfoitem.id) {
                    this.$set(
                      questionshowlistitem,
                      "content",
                      stuffinfoitem.content
                    );
                  }
                });
              }
              if (questionshowlistitem.part_id == paperpartitem.id) {
                if (questionshowlistitem.is_stuff == 1) {
                  const isarr = paperpartitem.children.some(
                    (itt) =>
                      itt.id == questionshowlistitem.stuff_id && itt.type == 2
                  );
                  const findindex = paperpartitem.children.findIndex(
                    (itt) =>
                      itt.id == questionshowlistitem.stuff_id && itt.type == 2
                  );
                  if (!isarr) {
                    const obj = {
                      children: [],
                      id: questionshowlistitem.stuff_id,
                      label: "",
                      type: 2,
                      content: questionshowlistitem.content,
                    };
                    obj.children.push(questionshowlistitem);
                    paperpartitem.children.push(obj);
                  } else {
                    paperpartitem.children[findindex].children.push(
                      questionshowlistitem
                    );
                  }
                } else {
                  paperpartitem.children.push(questionshowlistitem);
                }
              }
            });
          }
        });
        setTimeout(() => {
          this.data1 = this.numcl(paper_part);
        }, 50);
      } else {
        const arrdata = [];
        if (question_show_list != null && question_show_list.length > 0) {
          question_show_list.map((questionshowlistitem) => {
            if (questionshowlistitem.is_stuff == 1) {
              stuff_info.map((stuffinfoitem) => {
                if (questionshowlistitem.stuff_id == stuffinfoitem.id) {
                  this.$set(
                    questionshowlistitem,
                    "content",
                    stuffinfoitem.content
                  );
                }
              });
            }
            if (questionshowlistitem.is_stuff == 1) {
              const isarr = arrdata.some(
                (itt) =>
                  itt.id == questionshowlistitem.stuff_id && itt.type == 2
              );
              const findindex = arrdata.findIndex(
                (itt) =>
                  itt.id == questionshowlistitem.stuff_id && itt.type == 2
              );
              if (!isarr) {
                const obj = {
                  children: [],
                  id: questionshowlistitem.stuff_id,
                  label: "",
                  type: 2,
                  content: questionshowlistitem.content,
                };
                obj.children.push(questionshowlistitem);
                arrdata.push(obj);
              } else {
                arrdata[findindex].children.push(questionshowlistitem);
              }
            } else {
              arrdata.push(questionshowlistitem);
            }
          });
        }
        setTimeout(() => {
          this.data1 = this.numcl(arrdata);
        }, 30);
      }
    },
    numcl(data) {
      let cailiaoindex = 0;
      let shitiindex = 0;
      this.total_score = 0;
      this.question_num = 0;
      let noBfpx = 0;
      data.map((item, index) => {
        if (item.type == 1) {
          let question_count = 0;
          let part_total_score = 0;
          let haveBfpx = 0;
          this.$set(item, "seq", "bf-" + (index + 1));
          if (item.children.length > 0) {
            item.children.map((it) => {
              if (it.type == 3) {
                shitiindex++;
                question_count++;
                haveBfpx++;
                this.$set(it, "paixu", haveBfpx);
                part_total_score += Number(it.score);
                this.total_score += Number(it.score);
                this.question_num++;
                this.$set(it, "seq", "st-" + shitiindex);
                if (!it.label) {
                  this.$set(it, "label", "试题" + shitiindex);
                }
                this.$set(it, "clid", "");
                this.$set(it, "part_id", item.id);
                this.$set(item, "question_count", question_count);
                this.$set(item, "part_total_score", part_total_score);
                this.$set(it, "part_seq", index + 1);
                this.$set(it, "stuff_seq", 0);
              }
              if (it.type == 2) {
                cailiaoindex++;
                this.$set(it, "seq", "cl-" + cailiaoindex);
                this.$set(it, "label", "材料" + cailiaoindex);
              }
              this.$set(it, "bfid", item.id);
              this.$set(item, "part_id", item.id);
              if (it.children != undefined) {
                it.children.map((i) => {
                  shitiindex++;
                  question_count++;
                  haveBfpx++;
                  this.$set(i, "paixu", haveBfpx);
                  part_total_score += Number(i.score);
                  this.total_score += Number(i.score);
                  this.question_num++;
                  this.$set(i, "seq", "st-" + shitiindex);
                  if (!i.label) {
                    this.$set(i, "label", "试题" + shitiindex);
                  }
                  this.$set(item, "question_count", question_count);
                  this.$set(item, "part_total_score", part_total_score);
                  this.$set(i, "bfid", item.id);
                  this.$set(i, "clid", it.id);
                  this.$set(i, "part_id", item.id);
                  this.$set(i, "stuff_id", it.id);
                  this.$set(i, "part_seq", index + 1);
                  this.$set(i, "stuff_seq", cailiaoindex);
                  this.$set(i, "is_stuff", 1);
                });
              }
            });
            const ishaschild = item.children.some((t) => t.children > 0); // 判断是否有试题
            if (ishaschild) {
              this.$set(item, "question_count", 0);
              this.$set(item, "part_total_score", 0);
            }
          } else {
            this.$set(item, "question_count", question_count);
            this.$set(item, "part_total_score", part_total_score);
          }
          // this.$set(item, 'label', '第' + this.toChinese(index + 1) + '部分（共' + item.question_count + '题，' + item.part_total_score + '分）')
        }
        if (item.type == 2) {
          if (item.children) {
            cailiaoindex++;
            this.$set(item, "seq", "cl-" + cailiaoindex);
            this.$set(item, "label", "材料" + cailiaoindex);
            item.children.map((it) => {
              shitiindex++;
              noBfpx++;
              this.$set(it, "paixu", noBfpx);
              this.total_score += Number(it.score);
              this.question_num++;
              this.$set(it, "seq", "st-" + shitiindex);
              if (!it.label) {
                this.$set(it, "label", "试题" + shitiindex);
              }
              this.$set(it, "clid", item.id);
              this.$set(it, "part_id", 0);
              this.$set(it, "stuff_id", item.id);
              this.$set(it, "part_seq", 0);
              this.$set(it, "stuff_seq", cailiaoindex);
              this.$set(it, "is_stuff", 1);
            });
          }
        }
        if (item.type == 3) {
          shitiindex++;
          noBfpx++;
          this.$set(item, "paixu", noBfpx);
          this.total_score += Number(item.score);
          this.question_num++;
          this.$set(item, "seq", "st-" + shitiindex);
          if (!item.label) {
            this.$set(item, "label", "试题" + shitiindex);
          }
          this.$set(item, "part_seq", 0);
          this.$set(item, "stuff_seq", 0);
          this.$set(item, "part_id", 0);
          this.$set(item, "stuff_id", 0);
        }
      });
      this.isHavaBf(data);
      console.log(data);
      return data;
    },
    //多选高亮处理
    sub(list1) {
      if (typeof list1.result == "string") {
        list1.result = list1.result.split(",");
      }
      if (list1.radio.length > 0) {
        list1.radio.sort();
        let check = JSON.parse(JSON.stringify(list1.radio.sort()));
        if (list1.result.toString() == check.toString()) {
          this.$set(list1, "flag", "回答正确");
          this.$set(list1, "right", 0);
          list1.radio.map((item) => {
            list1.question_option.map((ite) => {
              if (item == ite.option_mark) {
                this.$set(ite, "className", "active");
              }
            });
          });
        } else {
          this.$set(list1, "flag", "回答错误");
          this.$set(list1, "right", 1);
          list1.question_option.map((ite) => {
            list1.radio.map(() => {
              if (
                ite.is_correct == 1 &&
                list1.radio.indexOf(ite.option_mark) != -1
              ) {
                this.$set(ite, "className", "active");
              } else if (
                ite.is_correct == 1 &&
                list1.radio.indexOf(ite.option_mark) == -1
              ) {
                this.$set(ite, "className", "right");
              } else if (list1.radio.indexOf(ite.option_mark) != -1) {
                this.$set(ite, "className", "error");
              } else {
                this.$set(ite, "className", "");
              }
            });
          });
        }
      } else {
        list1.question_option.map((ite) => {
          list1.result.map((item) => {
            if (item == ite.option_mark) {
              this.$set(ite, "className", "right");
            }
          });
        });
        this.$set(list1, "flag", "回答错误");
        this.$set(list1, "right", 1);
      }
    },
    //单选、判断高亮处理
    radioOptions(list1) {
      if (list1.user_answer != "") {
        list1.question_option.forEach((item) => {
          if (
            item.option_mark != list1.result &&
            list1.user_answer == item.option_mark
          ) {
            this.$set(item, "className", "error");
            this.$set(list1, "flag", "回答错误");
            this.$set(list1, "right", 1);
          } else if (
            item.option_mark == list1.result &&
            list1.user_answer == item.option_mark
          ) {
            this.$set(item, "className", "active");
            this.$set(list1, "flag", "回答正确");
            this.$set(list1, "right", 0);
          } else if (
            item.option_mark == list1.result &&
            list1.user_answer != item.option_mark
          ) {
            this.$set(item, "className", "right");
            this.$set(list1, "flag", "回答错误");
            this.$set(list1, "right", 1);
          }
        });
      } else {
        list1.question_option.forEach((item) => {
          if (
            item.option_mark == list1.result &&
            list1.user_answer != item.option_mark
          ) {
            this.$set(item, "className", "right");
            this.$set(list1, "flag", "回答错误");
            this.$set(list1, "right", 1);
          }
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
  .bgfffs{ width: 100%; background: #fff; text-align: center; padding: 200px 0;}
.borR8 {
  border-radius: 8px;
}
.topFix {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 12px 20px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  button {
    padding: 10px 30px;
    font-size: 14px;
    line-height: 18px;
  }
  button.back {
    background: #f3f4f7;
    border-color: #f3f4f7;
    color: #888;
    padding: 8px 15px;
  }
}
.contMain {
  width: 1360px;
  margin: 82px auto 20px;
  .intr {
    text-align: center;
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 20px;
  }
  .types {
    padding: 13px 30px;

    line-height: 26px;
    b {
      line-height: 26px;
      font-size: 18px;
    }
  }
  .gradeTotal {
    display: flex;
    dl {
      width: 260px;
      display: flex;
      align-items: center;
      dt {
        margin-right: 10px;
      }
      dd {
        font-size: 14px;
        b {
          font-size: 20px;
          line-height: 26px;
        }
        p {
          color: #888;
          line-height: 20px;
          margin-top: 4px;
        }
        div{
          b {
            font-size: 20px;
            line-height: 26px;
          }
        }
      }
    }
  }
  .summary {
    margin-bottom: 20px;
    line-height: 20px;
    font-size: 14px;
    color: #888;
    text-align: right;
    span {
      position: relative;
      padding-left: 16px;
    }
    span::before {
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 10px;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    span,
    em {
      margin-left: 20px;
      font-style: normal;
    }
    span.right::before {
      background: #42c9ab;
    }
    span.error::before {
      background: #fe4d67;
    }
    span.discuss::before {
      background: #507fff;
    }
    span.not::before {
      background: #cecece;
    }
    .el-checkbox__inner {
      border-radius: 50px;
    }
    .el-checkbox {
      color: #888;
    }
  }
  .testQuestions {
    margin-bottom: 20px;
    background: #fff;
    border-radius: 3px;
    padding: 24px 30px;
  }
}
</style>
