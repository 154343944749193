<template>
  <!-- 多选 -->
  <div class="checkWrap" :id="'question' + list.seq">
    <b class="itemNum">{{ list.label }}<span class="detailScore">（{{list.question_score}}分）</span></b>
    <div class="itemDetail">
      <div class="detailTitle">
        <div class="mb10">
          <span class="detailMate" v-if="is_stuff == 1">资料</span>
          <span class="detailCate">{{
          list.rtype == 2 ? "多选题" : "不定项选择题"
        }}</span>
          <div class="detailContent" v-html="list.title"></div>
        </div>
      </div>
      <div class="detailOption">
        <el-checkbox-group v-model="list.radio" :disabled="true" v-if="list.reacType">
          <el-checkbox
            :label="item.option_mark"
            v-for="(item, index) in list.question_option"
            :key="index"
            :class="item.className"
          >
            <i class="optionIcon"></i>{{ item.option_mark }}
            <div class="optionContent" v-html="item.option_content"></div>
          </el-checkbox>
        </el-checkbox-group>
        <el-checkbox-group v-model="list.radio" :disabled="true" v-else>
          <el-checkbox
                  :label="item.option_mark"
                  v-for="(item, index) in list.question_option"
                  :key="index"
                  :class="list.radio.includes(item.option_mark)?'select':''"
          >
            <i class="optionIcon"></i>{{ item.option_mark }}
            <div class="optionContent" v-html="item.option_content"></div>
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <Point :thought_dial="list" v-if="list.reacType"></Point>
    </div>
    <i></i>
  </div>
</template>

<script>
import Point from "./com/Point.vue";

export default {
  props: {
    list: Object,
    is_stuff: Number
  },
  components: {
    Point,
  },
  data() {
    return {};
  },
  mounted() {

  },
  methods: {

  },
};
</script>

<style scoped lang=""></style>
